import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { Wilson } from 'src/def/Wilson';
import GetUnitReportResponse = Wilson.GetUnitReportResponse;

@Injectable({
  providedIn: 'root',
})
export class UnitReportService {
  constructor(private apiService: ApiService) {}

  getUnitReport(
    classId: string,
    testId: string
  ): Observable<GetUnitReportResponse> {
    return this.apiService.get<GetUnitReportResponse>(
      `unitReports/GetUnitReport/${classId}/${testId}`
    );
  }

  GetUnitReportResponseDetails(
    classId: string,
    testId: string
  ): Observable<UnitReportCategoryDetails> {
    return this.apiService.get<UnitReportCategoryDetails>(
      `unitReports/GetUnitReportResponseDetails/${classId}/${testId}`
    );
  }
}

export interface UnitReportCategoryDetails {
  categoryId: number;
  categoryName: string;
  questionSummaryResponse: {
    percentCorrect: number;
    totalCorrect: number;
    totalStudents: number;
  }[];
  studentResponses: unknown[];
}
