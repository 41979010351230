import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from '@wilson/wilsonng';
import { Observable, tap } from 'rxjs';
import { StudentReportService } from 'src/app/services/student-report.service';
import mixpanel from 'mixpanel-browser';
import { InitialLoadService } from 'src/app/services/initial-load.service';
import { Wilson } from 'src/def/Wilson';
import BenchmarkScoresViewModel = Wilson.BenchmarkScoresViewModel;
import GetStudentReportResponse = Wilson.GetStudentReportResponse;
import StudentTestResponse = Wilson.StudentTestResponse;
import StudentTestAttempt = Wilson.StudentTestAttempt;
import { Translation, TranslocoService } from '@jsverse/transloco';

@Component({
  templateUrl: './student-report.component.html',
  styleUrls: ['./student-report.component.scss'],
})
export class StudentReportComponent implements OnInit {
  // this page is always set to English.
  language = 'en';
  languages = [
    {
      label: 'Print in English',
      routerLink: ['print'],
      queryParams: { lang: 'en' },
      target: '_blank',
      command: () => this.mixPanelLanguageTracking('English'),
    },
    {
      label: 'Print in Spanish',
      routerLink: ['print'],
      queryParams: { lang: 'es' },
      target: '_blank',
      command: () => this.mixPanelLanguageTracking('Spanish'),
    },
    {
      label: 'Print in French',
      routerLink: ['print'],
      queryParams: { lang: 'fr' },
      target: '_blank',
      command: () => this.mixPanelLanguageTracking('French'),
    },
    {
      label: 'Print in Chinese',
      routerLink: ['print'],
      queryParams: { lang: 'zh-CN' },
      target: '_blank',
      command: () => this.mixPanelLanguageTracking('Chinese (Simplified)'),
    },
  ];

  studentReport: GetStudentReportResponse;
  tests: StudentTestResponseExtended[];
  chartData: {
    datasets: [
      { label: string; fill: boolean; backgroundColor: string; data: number[] }
    ];
    labels: string[];
  };
  benchmarkScores: BenchmarkScoresViewModel;
  accordionReportUnits: boolean[];
  chartOptions = {
    scales: {
      y: {
        min: 0,
        max: 100,
      },
    },
    onClick: (_, data) => {
      if (!data.length) return;
      this.accordionReportUnits[data[0].index] = true;
    },
  };

  //used in attempt compare modal
  isAttemptCompareActive = false;
  isPrintView = false;
  bestAttempt: StudentTestAttempt;
  selectedAttempt: StudentTestAttempt;
  otherAttempts: StudentTestAttempt[];

  constructor(
    private studentReportService: StudentReportService,
    private spinnerService: SpinnerService,
    protected route: ActivatedRoute,
    private initialLoadService: InitialLoadService,
    private translocoService: TranslocoService
  ) {
    this.getStudentReport(
      route.snapshot.params['classId'],
      route.snapshot.params['studentId']
    ).subscribe();
  }

  ngOnInit(): void {
    this.mixpanelInitTracking();
    this.benchmarkScores = this.initialLoadService.initialLoad.benchmarkScores;
  }

  /*istanbul ignore next*/
  mixPanelLanguageTracking(language: string) {
    mixpanel.track('Student Report Translated', { language: language });
  }

  /*istanbul ignore next*/
  mixpanelInitTracking(): void {
    mixpanel.track('Go to Individual Student Report');
  }

  getStudentReport(
    classId: string,
    studentId: string
  ): Observable<GetStudentReportResponse> {
    this.spinnerService.show();
    return this.studentReportService.getStudentReport(classId, studentId).pipe(
      tap((studentReport: GetStudentReportResponse) => {
        this.studentReport = studentReport;
        this.tests = studentReport.tests as StudentTestResponseExtended[];
        this.accordionReportUnits = [...Array(studentReport.tests.length)];
        this.chartData = {
          datasets: null,
          labels: null,
        };
        this.processChartData().subscribe(() => this.spinnerService.hide());
      })
    );
  }

  processChartData(): Observable<Translation> {
    return this.translocoService.selectTranslation(this.language).pipe(
      tap(() => {
        const chartLabel = this.translocoService.translate(
          `print-student.score`,
          null,
          this.language
        );

        this.chartData.datasets = [
          {
            label: chartLabel,
            fill: true,
            backgroundColor: '#b3e7bd',
            data: this.studentReport.tests.map((test) => test.highestScore),
          },
        ];

        this.chartData.labels = this.studentReport.tests.map((test) =>
          this.getTestName(test.testDefinitionId)
        );

        this.tests = this.tests.map((test) => {
          test.name = this.getTestName(test.testDefinitionId);
          return test;
        });
      })
    );
  }
  launchScoreCompare(index: number): void {
    this.isAttemptCompareActive = true;
    this.bestAttempt = this.tests[index].attempts[0];
    this.otherAttempts = this.tests[index].attempts.slice(1);
    this.selectedAttempt = this.otherAttempts[0];
  }

  getTestName(testDefinitionId: number): string {
    const testDefinition =
      this.initialLoadService.initialLoad.testDefinitions.find(
        (def) => def.id === testDefinitionId
      );

    let key = 'unit-test-row';

    if (testDefinition.testNumber === 2 && testDefinition.unit === 6) {
      key = 'unit-test-row-bonus';
    } else if (
      testDefinition.testNumber === 1 &&
      testDefinition.programId === 2 &&
      testDefinition.unit === 1
    ) {
      key = 'unit-test-row-mid-unit';
    }

    return this.translocoService.translate(
      `print-student.${key}`,
      { unit: testDefinition.unit },
      this.language
    );
  }

  getTestId(_: number, test: StudentTestResponse): number {
    return test.testDefinitionId;
  }

  getQuestionId(_: number, question: string): string {
    return question;
  }

  getAreaId(_: number, area: any): string {
    return area.name;
  }

  /*istanbul ignore next*/
  trackShareProgress(): void {
    mixpanel.track('Share Student Progress', {
      studentId: this.route.snapshot.params['studentId'],
    });
  }
}

interface StudentTestResponseExtended extends StudentTestResponse {
  name: string;
}
