import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import {
  UnitReportCategoryDetails,
  UnitReportService,
} from 'src/app/services/unit-report.service';
import mixpanel from 'mixpanel-browser';
import { Wilson } from 'src/def/Wilson';
import { jsPDF } from 'jspdf';
import GetUnitReportResponse = Wilson.GetUnitReportResponse;
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { ExportingEvent } from 'devextreme/ui/data_grid';

@Component({
  templateUrl: './unit-report.component.html',
  styleUrls: ['./unit-report.component.scss'],
})
export class UnitReportComponent implements OnInit {
  unitReport: GetUnitReportResponse;
  categories: UnitReportCategoryDetails[];
  selectedCategoryIndex: number;
  responseKeys: string[][] = [];

  classId: string;
  testId: string;

  chartOptions = {
    parsing: {
      key: 'totalWrong',
    },
    onClick: (_, data) => {
      if (!data.length) return;
      this.selectedCategoryIndex = data[0].index;
      mixpanel.track('UTTActivity', {
        concept: this.categories[this.selectedCategoryIndex].categoryName,
      });
    },
  };

  constructor(
    private unitReportService: UnitReportService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.classId = this.route.snapshot.paramMap.get('classId');
    this.testId = this.route.snapshot.paramMap.get('testId');
    this.getUnitReport(this.classId, this.testId).subscribe();
    this.GetUnitReportResponseDetails(this.classId, this.testId).subscribe();
  }

  ngOnInit(): void {
    this.mixpanelInitTracking();
  }

  /*istanbul ignore next*/
  mixpanelInitTracking(): void {
    mixpanel.track('Go to Response Details');
  }

  getUnitReport(
    classId: string,
    testId: string
  ): Observable<GetUnitReportResponse> {
    return this.unitReportService.getUnitReport(classId, testId).pipe(
      tap((unitReport: GetUnitReportResponse) => {
        this.unitReport = unitReport;
      })
    );
  }

  GetUnitReportResponseDetails(
    classId: string,
    testId: string
  ): Observable<any> {
    return this.unitReportService
      .GetUnitReportResponseDetails(classId, testId)
      .pipe(
        tap((details: any) => {
          this.categories = details;
          this.selectedCategoryIndex = 0;

          this.categories.forEach((category) => {
            const questionsIds = Object.keys(category.studentResponses[0]);
            // isolates question IDs by removing studentId, studentName, and studentResponseSummary from object
            questionsIds.splice(-3);
            this.responseKeys = [...this.responseKeys, questionsIds];
          });
        })
      );
  }

  /*istanbul ignore next*/
  onExporting(e: ExportingEvent, categoryName: string): void {
    const doc = new jsPDF({
      orientation: 'landscape',
      putOnlyUsedFonts: true,
    });

    //Title Formatting
    const title = `Concept Summary for ${this.unitReport.className}`;
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(title, 14, 5);
    const subtitle = `${this.unitReport.name} | ${categoryName}`;
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.text(subtitle, 14, 11);

    exportDataGrid({
      jsPDFDocument: doc,
      component: e.component,
      customizeCell: ({ gridCell, pdfCell }) => {
        //Generic Formatting
        pdfCell.verticalAlign = 'middle';
        pdfCell.horizontalAlign = 'center';
        pdfCell.wordWrapEnabled = true;

        //Correct Responses
        if (
          gridCell.rowType === 'data' &&
          gridCell.column?.caption !== 'Name'
        ) {
          pdfCell.textColor =
            gridCell?.value?.correctResponse === true ? '#1a8908' : '#fa2222';
          pdfCell.text = gridCell?.value?.correctResponse === true ? 'O' : 'X';
        }

        //Student Names
        else if (
          gridCell.rowType === 'data' &&
          gridCell.column?.caption === 'Name'
        ) {
          pdfCell.text = gridCell?.data.studentName;
        }
        //QuestionText Headers
        else if (
          gridCell.rowType === 'header' &&
          gridCell.column?.caption !== 'Name'
        ) {
          //Calling ts-ignore because inside of this if block, caption is an object
          //otherwise, caption is a string
          // @ts-ignore
          pdfCell.text = gridCell.column?.caption.questionText;
        }

        //footer summary
        else if (gridCell.rowType === 'totalFooter') {
          if (pdfCell.text) {
            pdfCell.text = pdfCell.text.trim();
          }
        }

        // Score Summary
        if (
          gridCell.rowType === 'data' &&
          gridCell.column.cellTemplate === 'studentScoreTemplate'
        ) {
          const totalCorrect =
            gridCell.data?.studentResponseSummary.totalCorrect;
          const totalQuestion =
            gridCell.data?.studentResponseSummary.totalQuestions;
          const percentCorrect =
            gridCell.data?.studentResponseSummary.percentCorrect;

          pdfCell.textColor = '#000000';
          pdfCell.text = `(${totalCorrect}/${totalQuestion}) ${percentCorrect}%`;
        }
      },
      indent: 5,
    }).then(() => {
      doc.save(
        `${this.unitReport.className}-${this.unitReport.name}-${categoryName}.pdf`
      );
    });
  }

  returnToClass(): void {
    void this.router.navigate([
      '/roster',
      'class',
      this.route.snapshot.paramMap.get('classId'),
    ]);
  }

  getCategoryId(_: number, category: UnitReportCategoryDetails): string {
    return category.categoryName;
  }
}
