import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StudentReportComponent } from './student-report.component';
import { StudentReportService } from '../../../services/student-report.service';
import { SpinnerService } from '@wilson/wilsonng';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { registerLocaleData } from '@angular/common';
import frenchLocale from '@angular/common/locales/fr';
import spanishLocale from '@angular/common/locales/es';
import chineseLocale from '@angular/common/locales/zh';
import { InitialLoadService } from '../../../services/initial-load.service';

@Component({
  templateUrl: './student-report.component.html',
  styleUrls: ['./student-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrintStudentReportComponent
  extends StudentReportComponent
  implements OnInit
{
  language: string;

  constructor(
    studentReportService: StudentReportService,
    spinnerService: SpinnerService,
    route: ActivatedRoute,
    initialLoadService: InitialLoadService,
    translocoService: TranslocoService
  ) {
    super(
      studentReportService,
      spinnerService,
      route,
      initialLoadService,
      translocoService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.language = this.route.snapshot.queryParamMap.get('lang') ?? 'en';
    registerLocaleData(frenchLocale);
    registerLocaleData(spanishLocale);
    registerLocaleData(chineseLocale);
    this.isPrintView = true;
  }
}
