import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { Wilson } from 'src/def/Wilson';
import GetStudentReportResponse = Wilson.GetStudentReportResponse;

@Injectable({
  providedIn: 'root',
})
export class StudentReportService {
  constructor(private apiService: ApiService) {}

  getStudentReport(
    classId: string,
    studentId: string
  ): Observable<GetStudentReportResponse> {
    return this.apiService.get<GetStudentReportResponse>(
      `students/GetStudentReport/${classId}/${studentId}`
    );
  }
}
