<ng-container *ngIf="studentReport">
  <ng-container *transloco="let t; lang: language; read: 'print-student'">
    <div class="flex justify-content-between">
      <div>
        <h1 class="mb-1">
          {{ studentReport.name }}
        </h1>
        <p class="p-text-secondary mb-5">
          {{ t('title', { level: studentReport.programLevel }) }}
        </p>
        <p class="p-text-secondary mb-5">
          {{ studentReport.className }} {{ studentReport.classYear }}
        </p>
      </div>
      <p-splitButton
        *ngIf="studentReport.tests.length"
        [model]="languages"
        icon="pi pi-print"
        id="split-button"
        styleClass="p-button-outlined mr-2 mb-2 noprint"
        expandAriaLabel="Select Language"
        outlined
      >
        <ng-template pTemplate="content">
          <a
            (click)="trackShareProgress()"
            [routerLink]="['print']"
            class="ml-2 flex align-items-center brightness-90"
            data-automationId="share_progress_btn"
            target="_blank"
            >Print Progress</a
          >
        </ng-template>
      </p-splitButton>
    </div>

    <div *ngIf="studentReport.tests.length; else noTestResponses">
      <p-chart
        [data]="chartData"
        [options]="chartOptions"
        class="pb-3"
        data-automationId="scores_by_unit_chart"
        height="400"
        type="line"
      ></p-chart>

      <div class="flex justify-content-end">
        <p-button
          (click)="accordionReportUnits.fill(true)"
          icon="pi pi-plus"
          label="Expand All"
          styleClass="p-button-text noprint brightness-90"
        ></p-button>
        <p-button
          (click)="accordionReportUnits.fill(false)"
          icon="pi pi-minus"
          label="Collapse All"
          styleClass="p-button-text ml-2 noprint brightness-90"
        ></p-button>
      </div>

      <p-accordion [multiple]="true" styleClass="pt-3">
        <p-accordionTab
          *ngFor="let test of tests; index as i; trackBy: getTestId"
          [(selected)]="accordionReportUnits[i]"
        >
          <ng-template pTemplate="header">
            <div
              class="d-flex px-3 w-100 align-items-center justify-content-between"
            >
              <div>
                <span class="fw-bold">{{ test.name }}</span>

                <br />
                <span class="p-text-secondary">{{
                  test.createdDate | date : null : null : language
                }}</span>
              </div>
              <div>
                <p-tag
                  [rounded]="true"
                  [styleClass]="
                    test.highestScore >= benchmarkScores.onTrack.min
                      ? 'metric-tag tag-success'
                      : test.highestScore >= benchmarkScores.atRisk.min
                      ? 'metric-tag tag-warning'
                      : 'metric-tag tag-danger'
                  "
                  icon="pi pi-chart-line"
                  value="{{ t('score') }}: {{ test.highestScore }}"
                ></p-tag>
              </div>
            </div>
          </ng-template>
          <button
            *ngIf="test.attempts.length > 1 && !isPrintView"
            class="wlt-btn btn-link no-underline"
            (click)="launchScoreCompare(i)"
          >
            <span class="text-decoration-underline"
              >Highest Attempt Shown ( See All Attempts )</span
            >
          </button>
          <fieldset
            *ngFor="
              let category of test.attempts[0]?.questions | keyvalue;
              trackBy: getAreaId
            "
            class="small-legend"
          >
            <legend>
              {{ t('categories.' + category.key) }}
            </legend>
            <div class="flex flex-wrap gap-4 my-3">
              <div
                *ngFor="let question of category.value; trackBy: getQuestionId"
                class="d-flex gap-2 align-items-center"
              >
                <i
                  *ngIf="question.correctResponse"
                  alt="Correct response"
                  class="pi pi-check-circle"
                ></i>
                <i
                  *ngIf="!question.correctResponse"
                  alt="Incorrect response"
                  class="pi pi-times-circle"
                ></i>
                <p *ngIf="question.text">{{ question.text }}</p>
                <img
                  *ngIf="question.image"
                  [src]="'../../../assets/img/questions/' + question.image"
                  alt="image of {{ question.image }}"
                  class="question-image"
                />
              </div>
            </div>
          </fieldset>
        </p-accordionTab>
      </p-accordion>
    </div>
    <ng-template #noTestResponses>
      <div class="card">
        <p class="p-text-secondary text-center mt-3 mb-0">
          <em>No test data for {{ studentReport.name }}.</em>
        </p>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
<p-dialog
  [(visible)]="isAttemptCompareActive"
  header="Highest Score Achieved Attempt # {{ bestAttempt?.testNumber }}  ({{
    bestAttempt?.createdDate | date : null : null
  }})"
  [modal]="true"
  [style]="{ width: 'auto' }"
  styleClass="mx-3"
  [draggable]="false"
  [resizable]="false"
>
  <div class="container">
    <div class="row">
      <div class="col-md-6 left-column mt-4">
        <!--Best Attempt-->
        <div
          *ngFor="
            let category of bestAttempt?.questions | keyvalue;
            trackBy: getAreaId
          "
          class="mt-4"
        >
          <p class="fw-bold m-0 pt-2">
            {{ category.key }}
          </p>
          <div class="flex flex-wrap gap-4 my-3">
            <div
              *ngFor="let question of category.value; trackBy: getQuestionId"
              class="d-flex gap-2 align-items-center"
            >
              <i
                *ngIf="question.correctResponse"
                alt="Correct response"
                class="pi pi-check-circle"
              ></i>
              <i
                *ngIf="!question.correctResponse"
                alt="Incorrect response"
                class="pi pi-times-circle"
              ></i>
              <p *ngIf="question.text">{{ question.text }}</p>
              <img
                *ngIf="question.image"
                [src]="'../../../assets/img/questions/' + question.image"
                alt="image of {{ question.image }}"
                class="question-image"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 right-column">
        <!-- Other Attempts -->
        <div class="divider"></div>
        <div class="d-flex justify-content-end">
          <p-dropdown
            [options]="otherAttempts"
            [(ngModel)]="selectedAttempt"
            optionLabel="testNumber"
          >
            <ng-template pTemplate="selectedItem">
              <div *ngIf="selectedAttempt">
                <div>
                  Attempt # {{ selectedAttempt.testNumber }} ({{
                    selectedAttempt?.createdDate | date : null : null
                  }})
                </div>
              </div>
            </ng-template>
            <ng-template let-otherAttempts pTemplate="item">
              <div>
                Attempt # {{ otherAttempts.testNumber }} ({{
                  otherAttempts.createdDate | date : null : null
                }})
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div
          *ngFor="
            let category of selectedAttempt?.questions | keyvalue;
            trackBy: getAreaId
          "
          class="mb-5"
        >
          <p class="fw-bold m-0 pt-2">
            {{ category.key }}
          </p>
          <div class="flex flex-wrap gap-4 my-3">
            <div
              *ngFor="let question of category.value; trackBy: getQuestionId"
              class="d-flex gap-2 align-items-center"
            >
              <i
                *ngIf="question.correctResponse"
                alt="Correct response"
                class="pi pi-check-circle"
              ></i>
              <i
                *ngIf="!question.correctResponse"
                alt="Incorrect response"
                class="pi pi-times-circle"
              ></i>
              <p *ngIf="question.text">{{ question.text }}</p>
              <img
                *ngIf="question.image"
                [src]="'../../../assets/img/questions/' + question.image"
                alt="image of {{ question.image }}"
                class="question-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
