<ng-container *ngIf="unitReport">
  <ng-container *ngIf="unitReport.labels.length; else emptyPage">
    <h4>Response Details for {{ unitReport.name }}</h4>
    <p class="tp-text-secondary mb-5">
      {{ unitReport.className }} ({{ unitReport.classYear }})
    </p>
    <p class="p-text-secondary">
      This report shows the frequency that a specific concept was answered
      incorrectly for this unit.
    </p>

    <div class="grid flex-column lg:flex-row p-4">
      <div class="col-4">
        <p-chart
          [data]="unitReport"
          [options]="chartOptions"
          type="polarArea"
        ></p-chart>
      </div>
      <div class="col card p-5">
        <h4>Concept Summary</h4>

        <ng-container *ngIf="categories?.length > 0; else noCategories">
          <p class="p-text-secondary">
            Select a concept area on the chart or in the list below to view the
            breakdown of student performance within each test category.
          </p>

          <p-accordion
            [multiple]="true"
            [activeIndex]="[selectedCategoryIndex]"
            styleClass="pt-3"
          >
            <p-accordionTab
              *ngFor="let cat of categories; index as i; trackBy: getCategoryId"
            >
              <ng-template pTemplate="header">
                <div
                  class="d-flex px-3 w-100 align-items-center justify-content-between"
                >
                  <div>
                    <span class="fw-bold">{{ cat.categoryName }}</span>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="content">
                <dx-data-grid
                  #datagrid
                  [dataSource]="cat.studentResponses"
                  [rowAlternationEnabled]="true"
                  [showBorders]="true"
                  (onExporting)="onExporting($event, cat.categoryName)"
                  class="concept-summary-table"
                >
                  <dxo-sorting mode="none"></dxo-sorting>
                  <dxi-column
                    caption="Name"
                    cellTemplate="studentNameTemplate"
                    dataField="name"
                    minWidth="150"
                  >
                    <div *dxTemplate="let row of 'studentNameTemplate'">
                      <a
                        [routerLink]="[
                          '/reports/student',
                          classId,
                          row.data.studentId
                        ]"
                        trackClick="UTTViewStudent"
                        >{{ row.data.studentName }}</a
                      >
                    </div>
                  </dxi-column>

                  <dxi-column
                    *ngFor="let questionId of responseKeys[i]"
                    [caption]="cat.studentResponses[0][questionId]"
                    [dataField]="questionId"
                    alignment="center"
                    minWidth="50"
                    cellTemplate="questionTemplate"
                    headerCellTemplate="colHeaderTemplate"
                  >
                    <div *dxTemplate="let info of 'colHeaderTemplate'">
                      <ng-container
                        *ngIf="info.column.caption.questionImage; else noImage"
                      >
                        <img
                          class="question-image"
                          [src]="
                            '../../../assets/img/questions/' +
                            info.column.caption.questionImage
                          "
                          [alt]="info.column.caption.questionImage"
                        />
                      </ng-container>
                      <ng-template #noImage>{{
                        info.column.caption.questionText
                      }}</ng-template>
                    </div>
                    <div *dxTemplate="let row of 'questionTemplate'">
                      <i
                        *ngIf="row.value.correctResponse"
                        role="img"
                        aria-label="Correct response"
                        alt="Correct response"
                        title="Correct response"
                        class="pi pi-check-circle"
                      ></i>
                      <i
                        *ngIf="!row.value.correctResponse"
                        role="img"
                        aria-label="Correct response"
                        alt="Incorrect response"
                        title="Incorrect response"
                        class="pi pi-times-circle"
                      ></i>
                    </div>
                  </dxi-column>
                  <dxo-export [enabled]="true" [formats]="['pdf']"></dxo-export>

                  <dxi-column
                    caption=""
                    cellTemplate="studentScoreTemplate"
                    dataField="summary"
                    alignment="right"
                    minWidth="90"
                  >
                    <div *dxTemplate="let row of 'studentScoreTemplate'">
                      <div class="benchmark-values">
                        <span>{{
                          '(' +
                            row.data.studentResponseSummary.totalCorrect +
                            '/' +
                            row.data.studentResponseSummary.totalQuestions +
                            ') '
                        }}</span>
                        <span class="average-score">{{
                          row.data.studentResponseSummary.percentCorrect + '%'
                        }}</span>
                      </div>
                    </div>
                  </dxi-column>

                  <dxo-summary>
                    <dxi-total-item
                      column="name"
                      cssClass="benchmark-text"
                      displayFormat="% of Class with Correct Responses"
                    ></dxi-total-item>
                    <ng-container>
                      <dxi-total-item
                        *ngFor="let questionId of responseKeys[i]; index as x"
                        column="{{ questionId }}"
                        cssClass="benchmark-values"
                        displayFormat="{{
                          cat.questionSummaryResponse[x].totalCorrect +
                            '/' +
                            cat.questionSummaryResponse[x].totalStudents
                        }}"
                      ></dxi-total-item>
                    </ng-container>
                    <dxi-total-item
                      *ngFor="let questionId of responseKeys[i]; index as y"
                      column="{{ questionId }}"
                      cssClass="benchmark-values average-score"
                      displayFormat="
                      {{ cat.questionSummaryResponse[y].percentCorrect + '%' }}"
                    ></dxi-total-item>
                  </dxo-summary>
                </dx-data-grid>
              </ng-template>
            </p-accordionTab>
          </p-accordion>
        </ng-container>

        <ng-template #noCategories>
          <p class="p-text-secondary">
            No responses have been recorded for this Unit's test.
          </p>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <ng-template #emptyPage>
    <div class="card card-cluster emptyCardCluster">
      <div class="card">
        <h1 class="text-primary"><strong>Wow!</strong></h1>
        <p>This class hasnt recorded any incorrect answers!</p>
        <p>
          If any incorrect answers are logged for the
          <strong>{{ unitReport.name }}</strong> in the future, they will appear
          here
        </p>
        <button (click)="returnToClass()" pButton>
          Click here to return to your class roster
        </button>
      </div>
    </div>
  </ng-template>
</ng-container>
